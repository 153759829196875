/********** Add Your Global CSS Here **********/
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
html,
body,
html a {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  margin-bottom: 0;
}

p {
  margin-bottom: 0;
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/********** Add Your Global RTL CSS Here **********/
/* Popover */
html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */
html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */
html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */
html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */
html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */
html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */
html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */
html[dir='rtl'] .dz-hidden-input {
  display: none;
}

.ant-form-item-children-icon {
  display: none;
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  animation: none;
  flex-wrap: nowrap;
  direction: ltr;
  @media (max-width: 768px) {
    display: inline-grid;
  }
}

/* Instagram Modal */

.ant-modal-wrap.instagram-modal .ant-modal {
  max-width: 935px;
  width: 100% !important;
}

.button-delete-batch,
.button-delete-batch:focus {
  border-color: #ff4d4f;
  color: #ff4d4f;
}

.button-delete-batch:hover {
  background-color: #ff4d4f;
  color: #fff;
  border-color: #ff4d4f;
}

.button-enable-batch,
.button-enable-batch:focus {
  border-color: #6ac63f;
  color: #6ac63f;
}

.button-enable-batch:hover {
  background-color: #6ac63f;
  color: #fff;
  border-color: #6ac63f;
}

.button-no-confidential,
.button-no-confidential:focus {
  border-color: #16a085;
  color: #16a085;
}

.button-no-confidential:hover {
  background-color: #16a085;
  color: #fff;
  border-color: #16a085;
}

.button-confidential,
.button-confidential:focus {
  border-color: #a04000;
  color: #a04000;
}

.button-confidential:hover {
  background-color: #a04000;
  color: #fff;
  border-color: #a04000;
}

.button-export-tax,
.button-export-tax:focus {
  border-color: #239b56;
  color: #fff;
  background-color: #239b56;
}

.button-export-tax:hover {
  background-color: #239b56;
  color: #fff;
  border-color: #239b56;
}

.button-disable-batch,
.button-disable-batch:focus {
  border-color: #f0840b;
  color: #f0840b;
}

.button-disable-batch:hover {
  background-color: #f0840b;
  color: #fff;
  border-color: #f0840b;
}

.button-add,
.button-add:hover {
  width: 100%;
  border-color: #008000;
  color: #fff;
  background-color: #008000;
}

.button-add-income,
.button-add-income:hover {
  width: 100%;
  border-color: #008000;
  color: #fff;
  background-color: #008000;
}

.button-add-expenditure,
.button-add-expenditure:hover {
  width: 100%;
  border-color: #6c0808;
  color: #fff;
  background-color: #6c0808;
}

.button-search,
.button-search:focus {
  color: #659be0;
  border-color: #659be0;
}

.button-search:hover {
  background-color: #659be0;
  border-color: #659be0;
  color: #fff;
}

.button-clean-filter,
.button-clean-filter:focus {
  color: #e50695;
  border-color: #e50695;
}

.button-clean-filter:hover {
  background-color: #e50695;
  border-color: #e50695;
  color: #fff;
}

.button-edit,
.button-edit:focus {
  color: #fff;
  border-color: #00a0df;
  background-color: #00a0df;
}

.button-edit:hover {
  border-color: #0283b6;
  background-color: #0283b6;
  color: #fff;
}

.button-logs-row,
.button-logs-row:focus {
  color: #fff;
  border-color: #323e48;
  background-color: #323e48;
}

.button-logs-row:hover {
  color: #fff;
  border-color: #323e48;
  background-color: #323e48;
}

.button-logs-login,
.button-logs-login:focus {
  color: #fff;
  border-color: #7f60f3;
  background-color: #7f60f3;
}

.button-logs-login:hover {
  color: #fff;
  border-color: #5128ec;
  background-color: #5128ec;
}

.button-enable-row,
.button-enable-row:focus {
  color: #fff;
  border-color: #6ac63f;
  background-color: #6ac63f;
}

.button-enable-row:hover {
  color: #fff;
  border-color: #5fad3a;
  background-color: #5fad3a;
}

.button-disable-row,
.button-disable-row:focus {
  color: #fff;
  border-color: #f0840b;
  background-color: #f0840b;
}

.button-disable-row:hover {
  color: #fff;
  border-color: #cf720a;
  background-color: #cf720a;
}

.button-delete-row,
.button-delete-row:focus {
  color: #fff;
  border-color: #fd5353;
  background-color: #fd5353;
}

.button-delete-row:hover {
  color: #fff;
  border-color: #ff1d1d;
  background-color: #ff1d1d;
}

.button-coupon-pdf-row,
.button-coupon-pdf-row:focus {
  color: #fff;
  border-color: #f8512e;
  background-color: #f8512e;
}

.button-coupon-pdf-row:hover {
  color: #fff;
  border-color: #dd4b2d;
  background-color: #dd4b2d;
}

.button-link-row,
.button-link-row:focus {
  color: #fff;
  border-color: #1aa153;
  background-color: #1aa153;
}

.button-link-row:hover {
  color: #fff;
  border-color: #1aa153;
  background-color: #1aa153;
}

.button-unlink-row,
.button-unlink-row:focus {
  color: #1aa153;
  border-color: #1aa153;
  background-color: #fff;
}

.button-unlink-row:hover {
  color: #1aa153;
  border-color: #1aa153;
  background-color: #fff;
}

.button-verify-order-charge-row,
.button-verify-order-charge-row:focus {
  color: #fff;
  border-color: #9c36ff;
  background-color: #9c36ff;
}

.button-verify-order-charge-row:hover {
  color: #fff;
  border-color: #9c36ff;
  background-color: #9c36ff;
}

.button-public-row,
.button-public-row:focus {
  color: #fff;
  border-color: #16a085;
  background-color: #16a085;
}

.button-public-row:hover {
  color: #fff;
  border-color: #28b463;
  background-color: #28b463;
}

.button-confidential-row,
.button-confidential-row:focus {
  color: #fff;
  border-color: #a04000;
  background-color: #a04000;
}

.button-confidential-row:hover {
  color: #fff;
  border-color: #d35400;
  background-color: #d35400;
}

.button-view,
.button-view:focus {
  color: #fff;
  border-color: #ba60f8;
  background-color: #ba60f8;
}

.button-view:hover {
  color: #fff;
  border-color: #7906c8;
  background-color: #7906c8;
}

.button-copy,
.button-copy:focus {
  color: #fff;
  border-color: #39e7e7;
  background-color: #39e7e7;
}

.button-copy:hover {
  color: #fff;
  border-color: #42cdcd;
  background-color: #42cdcd;
}

.button-permissions,
.button-permissions:focus {
  color: #fff;
  border-color: #1b22f6;
  background-color: #1b22f6;
}

.button-permissions:hover {
  color: #fff;
  border-color: #1c22d3;
  background-color: #1c22d3;
}

.button-tax-data,
.button-tax-data:focus {
  color: #fff;
  border-color: #22d167;
  background-color: #22d167;
}

.button-tax-data:hover {
  border-color: #25ef74;
  background-color: #25ef74;
  color: #fff;
}

.button-transactions-row,
.button-transactions-row:focus {
  color: #fff;
  border-color: #4a0dee;
  background-color: #4a0dee;
}

.button-transactions-row:hover {
  color: #fff;
  border-color: #5111fe;
  background-color: #5111fe;
}

.button-transfers-row,
.button-transfers-row:focus {
  color: #fff;
  border-color: #d110b9;
  background-color: #d110b9;
}

.button-transfers-row:hover {
  color: #fff;
  border-color: #e812cd;
  background-color: #e812cd;
}

.button-verify-row,
.button-verify-row:focus {
  color: #fff;
  border-color: #12a0bd;
  background-color: #12a0bd;
}

.button-verify-row:hover {
  color: #fff;
  border-color: #19c4e7;
  background-color: #19c4e7;
}

.button-files-row,
.button-files-row:focus {
  color: #fff;
  border-color: #c56315;
  background-color: #c56315;
}

.button-files-row:hover {
  color: #fff;
  border-color: #f0791b;
  background-color: #f0791b;
}

.ant-table-row-expand-icon-collapsed {
  display: none !important;
}

.list-transactions .ant-table-row-expand-icon-collapsed {
  display: block !important;
}

.line-bottom {
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.row-next-mobile-table {
  color: #00a0df;
  font-size: 16px;
  display: none;
}

@media only screen and (max-width: 991px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    padding: 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .ant-modal-wrap.instagram-modal .ant-modal {
    max-width: 580px;
  }

  .button-no-confidential,
  .button-confidential,
  .button-delete-batch,
  .button-enable-batch,
  .button-disable-batch,
  .button-search,
  .button-clean-filter,
  .button-width-100 {
    width: 100%;
  }

  .hidden-mobile {
    display: none !important;
  }

  .ant-table-row-expand-icon-collapsed {
    display: block !important;
  }

  .row-next-mobile-table {
    display: block;
  }

  .first-column-mobile {
    overflow-wrap: break-word;
    float: left;
    width: 100px;
    white-space: normal;
  }

  .content-text-name-table-mobile {
    width: 150px;
  }
}

.ant-modal-wrap.instagram-modal .ant-modal-content {
  border-radius: 0;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close {
  position: fixed;
  color: #fff;
}

.ant-modal-wrap.instagram-modal .ant-modal-content button.ant-modal-close i {
  font-size: 24px;
}

.ant-modal-wrap.instagram-modal .ant-modal-content .ant-modal-body {
  padding: 0;
}

.list-bank-accounts table td.ant-table-cell-fix-right {
  background-color: #fff !important;
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.btn-save-close,
.btn-save-close:hover,
.btn-save-close:focus {
  background-color: #5aa9fa;
  color: #fff;
}

/*Para el wizard*/
ol.progtrckr {
  list-style-type: none;
  padding: 0;
  display: block;
}

ol.progtrckr li.progtrckr-doing {
  color: black;
  border-bottom: 4px solid #cccccc;
}

.progtrckr {
  text-align: center;
  padding-bottom: 16px;
}

.progtrckr li {
  margin-bottom: 10px;
}

ol.progtrckr li {
  display: inline-block;
  text-align: center;
  line-height: 2.5rem;
}

ol.progtrckr li.progtrckr-doing:before {
  content: '\2022';
  color: white;
  background-color: #cccccc;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
  margin-top: 6px;
}

ol.progtrckr li:before {
  position: relative;
  bottom: -1.7rem;
  float: left;
  left: 50%;
}

.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

ol.progtrckr li span {
  padding: 0 1.5rem;
}

ol.progtrckr li:after {
  content: '\A0\A0';
}

ol.progtrckr li.progtrckr-todo {
  color: silver;
  border-bottom: 4px solid silver;
}

ol.progtrckr li.progtrckr-todo:before {
  background-color: #f2f9ff;
}

ol.progtrckr li.progtrckr-todo:before {
  content: '\39F';
  color: silver;
  background-color: #f1f3f6;
  width: 1.2em;
  line-height: 1.4em;
  margin-top: 6px;
}

ol.progtrckr li.progtrckr-done {
  color: black;
  border-bottom: 4px solid #5cb85c;
}

ol.progtrckr li.progtrckr-done:before {
  content: '\2713';
  color: white;
  background-color: #5cb85c;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
  margin-top: 6px;
}

/*Estilos para las reservaciones*/

.item-reservation {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
  min-height: 150px;
  height: auto;
  border: 1px solid gainsboro;
  font-size: 12px;
}

.item-reservation .title {
  font-size: 16px;
}

.item-reservation:hover {
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2);
}

.item-reservation .container {
  padding: 10px 16px;
}

.item-reservation .separator {
  height: 1px;
  background-color: gray;
}

.item-reservation .btn-add-reservation {
  height: 100px;
  background-color: #008000;
  color: #fff;
  margin-top: 70%;
}

.item-reservation .btn-delete-reservation {
  height: 100px;
  background-color: #ff1d1d;
  color: #fff;
  margin-top: 70%;
}

.total-pending-reservations {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 10px 16px;
  height: auto;
  border: 1px solid gainsboro;
  text-align: right;
  font-weight: bold;
  font-size: 16px;
}

.btn-add-all-reservation,
.btn-add-all-reservation:hover,
.btn-add-all-reservation:focus {
  height: auto;
  margin: 0px;
  background-color: #008000;
  color: #fff;
}

.btn-delete-all-reservation,
.btn-delete-all-reservation:hover,
.btn-delete-all-reservation:focus {
  height: auto;
  margin: 0px;
  background-color: #ff1d1d;
  color: #fff;
}

.button-save-order-charge,
.button-save-order-charge:hover,
.button-save-order-charge:focus {
  color: #fff;
  background-color: #008000;
}

.button-cancel-order-charge,
.button-cancel-order-charge:hover,
.button-cancel-order-charge:focus {
  color: #fff;
  background-color: #ff1d1d;
}

.separator-view {
  width: 100%;
  height: 1px;
  background-color: gray;
  margin-top: 25px;
  margin-bottom: 25px;
}

.info-pdf {
  width: 100%;
  height: auto;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 20px;
  padding: 20px;
  margin: auto;
  margin-bottom: 25px;
}

#list-orders-charges-pdf {
  border-collapse: collapse;
  width: 100%;
}

#list-orders-charges-pdf td,
#list-orders-charges-pdf th {
  border: 1px solid #ddd;
  padding: 8px;
}

#list-orders-charges-pdf tr:nth-child(even) {
  background-color: #f2f2f2;
}

#list-orders-charges-pdf thead th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #717d7e;
  color: white;
}

#list-orders-charges-pdf tfoot {
  font-size: 20px;
}

#list-orders-charges-pdf tfoot td {
  border: 0px;
}

#list-orders-charges-pdf tfoot .total-label {
  text-align: right;
}

.title-banks-pdf {
  text-align: center;
  font-size: 18px;
}

.row_selected_3_1 {
  background-color: #2d9948;
}

.row_selected_3_1 span {
  color: #fff;
}

.row_no_selected_3_1 {
  cursor: pointer;
}

.pdf-title-order {
  font-weight: bold;
  font-size: 50px;
}

.btn-copy-url,
.btn-download-pdf {
  background: #e91e63;
  width: 150px;
  height: auto;
  padding: 15px;
  position: fixed;
  cursor: pointer;
  display: block;
  color: #fff;
  text-align: center;
  font-size: 16px;
  border-radius: 10px;
}

.btn-copy-url {
  top: 76%;
  right: 10px;
}

.btn-download-pdf {
  top: 85%;
  right: 10px;
}

.btn-copy-url a {
  color: #fff;
}

.btn-copy-url:hover,
.btn-copy-url:hover a,
.btn-download-pdf:hover,
.btn-download-pdf:hover a {
  background-color: #2c2fa5;
  color: #fff;
}

/*Para el wizard footer*/
ol.progtrckr2 {
  list-style-type: none;
  padding: 0;
  display: block;
}

ol.progtrckr2 li.progtrckr-doing {
  color: black;
  border-top: 4px solid #cccccc;
}

.progtrckr2 {
  text-align: center;
  padding-bottom: 16px;
}

.progtrckr2 li {
  margin-top: 10px;
}

ol.progtrckr2 li {
  display: inline-block;
  text-align: center;
  line-height: 2.5rem;
}

ol.progtrckr2 li.progtrckr-doing:before {
  content: '\2022';
  color: white;
  background-color: #cccccc;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
  margin-top: -38px;
}

ol.progtrckr2 li:before {
  position: relative;
  bottom: -1.7rem;
  float: left;
  left: 50%;
}

.progtrckr2 em {
  display: none;
  font-weight: 700;
  padding-left: 1rem;
}

ol.progtrckr2 li span {
  padding: 0 1.5rem;
}

ol.progtrckr2 li:after {
  content: '\A0\A0';
}

ol.progtrckr2 li.progtrckr-todo {
  color: silver;
  border-top: 4px solid silver;
}

ol.progtrckr2 li.progtrckr-todo:before {
  background-color: #f2f9ff;
}

ol.progtrckr2 li.progtrckr-todo:before {
  content: '\39F';
  color: silver;
  background-color: #f1f3f6;
  width: 1.2em;
  line-height: 1.4em;
  margin-top: -38px;
}

ol.progtrckr2 li.progtrckr-done {
  color: black;
  border-top: 4px solid #5cb85c;
}

ol.progtrckr2 li.progtrckr-done:before {
  content: '\2713';
  color: white;
  background-color: #5cb85c;
  width: 1.2em;
  line-height: 1.2em;
  border-radius: 1.2em;
  margin-top: -38px;
}

#bg-loading {
  width: 82%;
  background-color: rgba(0, 0, 0, 0.8);
  height: 500px;
  position: absolute;
  top: 0%;
  z-index: 1100;
  display: none;
}

/********** HOSTECH CUSTOM CSS **********/
.ant-descriptions-item-label {
  color: black;
  font-weight: bold;
}

.ant-descriptions-item-container {
  background: #f7f7f2;
  border-radius: 10px;
  padding: 8px;
  margin-right: 4px;
}

/********** FILTER REPORTS **********/
.filters-group {
  font-weight: bold;
  font-size: 15px;
  color: #3980e5;
}

.filter-separator {
  width: 100%;
  height: 2px;
  background-color: #3980e5;
}

@media only screen and (max-width: 1800px) {
  #total-bar .total-bar-card {
    display: flex;
    flex-direction: column;
    padding: 8px;
  }
  #total-bar .ant-statistic-content {
    font-size: 18px;
  }
}

.image-container {
  height: 220px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-overlay {
  display: flex;
  position: absolute;
  width: 100%;
  min-height: 220px;
  background-color: black;
  align-items: center;
  justify-content: center;
  z-index: 2;
  opacity: 0;
  color: white;
  flex-direction: column;
  &:hover {
    opacity: 0.7;
  }
}

#balance_text {
  color: rgb(70, 70, 70);
  transition: color 0.2s ease, opacity 0.2s ease;
  opacity: 1;
}
.balance_text_input {
  color: green;
  font-weight: 800;
  opacity: 0.7;
}

.disabled-row {
  background-color: #dcdcdc;
  pointer-events: none;
}

/* Clases nueva interfaz HostechUI */

.hostech-table-card {
  &:hover {
    .show-actions-buttons {
      visibility: visible;
    }
  }
}

.show-actions-buttons {
  visibility: hidden;
}
.modal-row {
  padding: 8px;
}

.modal-field-subtitle {
  font-size: 12px;
  color: #656565;
  line-height: 30px;
}

.modal-field-title {
  font-size: 14px;
  color: black;
  font-weight: 500;
  padding: 8px 6px;
  &:hover {
    color: #1790ff;
    cursor: pointer;
    .hide-edit-icon {
      visibility: visible;
    }
  }
}

.form-edit-btn {
  margin-left: 8px;
  color: #1790ff;
}

.hide-edit-icon {
  visibility: hidden;
}

.hover-upload-image {
  background: black;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  &:hover {
    opacity: 0.5;
  }
}

.loading-data {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
